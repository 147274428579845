import { memo, useEffect, useRef, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import classnames from 'classnames';
import gsap from 'gsap';

import styles from './VideoEmbed.module.scss';

import { Props as ImageProps } from '@/blocks/BlockImage/BlockImage';

import Image, { imageLayoutSizes } from '../Image/Image';
import PlayButton from '../PlayButton/PlayButton';

export type Props = {
  className?: string;
  allowResponsive?: boolean;
  caption?: string;
  previewable?: boolean;
  providerNameSlug?: string;
  responsive?: boolean;
  type?: string;
  url: string;
  poster?: ImageProps;
};

function VideoEmbed({ className, responsive = true, url, poster, type }: Props) {
  const [posterHidden, setPosterHidden] = useState(false);
  const videoEmbedRef = useRef<HTMLDivElement>(null);

  function handleClick() {
    setPosterHidden(!posterHidden);
    videoEmbedRef.current &&
      gsap.to(videoEmbedRef.current.children[0], {
        autoAlpha: 0,
        duration: 0.4,
        ease: 'ease1',
        pointerEvents: 'none'
      });
  }

  useEffect(() => {
    const q = gsap.utils.selector(videoEmbedRef.current);
    const timeline = gsap
      .timeline({
        delay: 0.2,
        scrollTrigger: {
          trigger: q('.button'),
          start: 'top 85%'
        }
      })
      .fadeInFrom(q('.button'), { y: 0 });

    return () => {
      timeline?.scrollTrigger?.kill();
      timeline?.kill();
    };
  }, []);

  useEffect(() => {
    setPosterHidden(!poster?.url);
  }, [poster]);

  return type === 'vimeo' ? (
    <div className={classnames(styles.VideoEmbed, className)} ref={videoEmbedRef}>
      {posterHidden ? (
        <Vimeo
          autoplay={poster?.url ? true : false}
          showTitle={false}
          className={styles.vimeoEl}
          video={url}
          playsInline={true}
          responsive={responsive}
        />
      ) : null}

      {poster?.url ? (
        <div className={styles.poster}>
          <Image
            src={poster.url ?? ''}
            srcWidths={imageLayoutSizes.fullBlockWidth}
            alt={poster.alt ?? ''}
            animateOnScroll={true}
          />
          <PlayButton
            className={classnames(styles.button, 'button')}
            onClick={handleClick}
            text="Play Reel"
          />
        </div>
      ) : null}
    </div>
  ) : (
    <div>Please specify Vimeo video</div>
  );
}

export default memo(VideoEmbed);
